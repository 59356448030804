<template>
  <div class="pt-5">
    <div class="mb-3" v-for="(mod, ind) in modules" :key="ind">
      <div class="accordion" :id="`accordionTasks${ind}`">
        <div class="accordion-item">
          <h4 class="accordion-header accordion-title" :id="`publishedTitle${ind}`">
            <button class="accordion-button collapsed"
            :class="[mod.completion == 100 ? 'bg-gray':'background-dark',
            firstIncomplete && mod.id == firstIncomplete.id ? '' :'collapsed']" type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#publishedTable${ind}`" aria-expanded="true"
              aria-controls="publishedTable">
              Module {{ind+1}}: {{mod.title}}
              <i class="material-icons text-success ms-2" v-show="mod.completion == 100">
                check_circle
              </i>
            </button>
            <div class="bg-accent-1" v-show="mod.completion < 100"
            :style="`height: 0.3rem; width: ${mod.completion}%`">
            </div>
          </h4>
          <div :id="`publishedTable${ind}`" class="accordion-collapse collapse"
            :class="[firstIncomplete && mod.id == firstIncomplete.id ? 'show' :'']"
            :aria-labelledby="`publishedTitle${ind}`" :data-bs-parent="`#accordionTasks${ind}`">
            <div class="bg-white p-0 accordion-card rounded shadow">
              <div class="row mx-0 py-3">
                <div class="col-md-9 d-flex flex-row justify-content-evenly mx-0">
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.readings + mod.lessons }}</h4>
                    <p class="detail-text">Lessons</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.files }}</h4>
                    <p class="detail-text">Files</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.assessments }}</h4>
                    <p class="detail-text">Assignments</p>
                  </div>
                </div>
                <div class="col-md-3 text-end">
                  <button class="btn btn-line-dark" @click="loadModule(mod)">
                    <i class="material-icons align-middle"> launch </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  name: 'StudentModulesList',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    firstIncomplete() {
      return this.modules.find((mod) => mod.completion < 100);
    },
  },
  data() {
    return {
      courseId: null,
      modules: [],
      newModule: '',
      moduleModal: null,
      submitNewModule: false,
      selectedModule: null,
      // mode: null,
      // code: null,
    };
  },
  validations: {
    newModule: {
      required,
    },
  },
  methods: {
    getModules() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module', {
        params: {
          courseId: this.courseId,
        },
      })
        .then((response) => {
          this.modules = response.data;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    loadModule(mod) {
      // this.$router.push(`editmodule/${seq}`);
      this.$parent.selectedModule = mod;
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.getModules();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.accordion-item {
  background-color: transparent;
  border:  none;
}
.accordion-title button{
  color: #fff;
  font-weight: bold;
/*  background: linear-gradient(90deg, rgba(0,33,71,1) 60%,
    rgba(0,62,109,1) 75%, rgba(0,103,165,1) 90%);*/
}
/*.accordion-card{
  max-height: 23em !important;
  overflow-y: scroll;
}*/
/*.accordion-button:not(.collapsed) {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}*/
.accordion-button {
  border-radius: 0.2rem 0.2rem 0 0 !important;
}
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}
.detail-number {
  color:  var(--accent-1);
}
.detail-text {
  color:  var(--dark-color);
  font-weight: bold;
  margin-bottom: 0;
}

</style>
