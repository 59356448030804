<template>
  <div v-if="assDetails">
    <h2> {{assDetails.title}} </h2>
    <small class="text-muted">
      {{assDetails.aType.type}}
      {{assDetails.totTime ? `-${assDetails.totTime} mins` : ''}}
    </small>
    <div class="mt-5">
      <p> <b> Assignment Information: </b> </p>
      <div class="d-inline me-3 text-muted">
        <b> Number of Attempts: </b> {{assDetails.att}}
      </div>
      <div class="d-inline text-muted">
        <b> Total Points: </b> {{assDetails.totPoints}}
      </div>
    </div>
    <div class="mt-5" v-if="assDetails.submitted">
      <p> <b> Submission Information: </b> </p>
      <div class="text-pri mb-4">
        <b class="text-muted"> Points Scored: </b>
        <span v-if="assDetails.releaseGrades">
          <span v-if="assDetails.points">
            <span class="fs-4"> {{assDetails.points}}/</span>
            {{assDetails.totPoints}}
          </span>
          <span v-else> Not Graded </span>
        </span>
        <span v-else> Not Released </span>
      </div>
      <div class="d-inline me-3 text-muted">
        <b> Attempts Remaining: </b>
        <span class="fs-6">{{assDetails.att - assDetails.attemptsUsed}}</span>
      </div>
      <div class="d-inline text-muted">
        <b> Last Submission: </b>
        <span class="fs-6">{{new Date(assDetails.submitted)}}</span>
      </div>
    </div>
    <div class="text-end mt-5 me-2">
      <button class="btn text-end text-dark me-2"
      v-show="assDetails.releaseGrades" @click="loadReview">
        Review Assignment
      </button>
      <button class="btn bg-pri text-end"
      v-show="attemptsRemaining" @click="startAssessment">
        Start assignment
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'ModuleAssessmentView',
  props: ['contentId', 'item'],
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    attemptsRemaining() {
      return (this.assDetails.att - this.assDetails.attemptsUsed) > 0;
    },
  },
  watch: {
    contentId() {
      this.getAssDetails();
    },
  },
  data() {
    return {
      assDetails: null,
      courseId: null,
      // mode: null,
      // code: null,
    };
  },
  methods: {
    getAssDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/assessment/getstudentassessmentinfo', {
        params: {
          aId: this.item.contentId,
          role: this.userData.roleId,
          userId: this.userData.id,
        },
      })
        .then((response) => {
          this.assDetails = response.data.assignmentInfo;
          console.log(this.assDetails);
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    startAssessment() {
      if ((this.assDetails.att - this.assDetails.attemptsUsed) === 1) {
        Swal.fire({
          title: 'Are you sure you want to start this assignment?',
          text: 'This will be your final attempt',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, start!',
        }).then((result) => {
          if (result.isConfirmed) {
            this.loadAssessment();
          }
        });
      } else {
        this.loadAssessment();
      }
    },
    loadAssessment() {
      if (!this.item.completed) {
        axios.post('/api/module/complete', {
          contentId: this.item.id,
          studentId: this.userData.id,
        })
          .then(() => {
            this.$set(this.item, 'completed', true);
            this.$router.push(`/submittask/${this.courseId}?aID=${this.contentId}`);
          })
          .catch((err) => { console.log(err); });
      } else {
        this.$router.push(`/submittask/${this.courseId}?aID=${this.contentId}`);
      }
    },
    loadReview() {
      this.$router.push(`/reviewtask/${this.courseId}?aID=${this.contentId}`);
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
  },
  beforeMount() {
    this.getAssDetails();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  background-color: rgba(251, 240, 218, 0.3);
  padding: 1rem 0;
}
.add-content {
  border:  dashed #ddd 2px;
  height: 5rem;
  margin: 1rem 2rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
</style>
