<template>
  <div>
    <div class="modal fade" id="createModule"
      tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <input type="text" class="form-control" placeholder="Enter Module Name"
            v-model="newModule"
            :class="{ 'is-invalid': submitNewModule && $v.newModule.$error }"
            />
            <div v-if="submitNewModule && !$v.newModule.required"
            class="form-text error">Module name is required
            </div>
            <div class="mt-2 text-end">
              <button type="button" class="btn bg-acc2"
                data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-pri ms-2"
                @click="createModule()">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-end mb-3 pt-3">
      <button class="btn btn-inline" @click="loadModuleCreation" v-show="editable">
        <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
      </button>
    </div>
    <div class="mb-3" v-for="(mod, ind) in modules" :key="ind">
      <div class="accordion" :id="`accordionTasks${ind}`">
        <div class="accordion-item">
          <h4 class="accordion-header accordion-title" :id="`publishedTitle${ind}`">
            <button class="accordion-button collapsed" type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#publishedTable${ind}`" aria-expanded="true"
              aria-controls="publishedTable">
              Module {{ind + 1}}: {{mod.title}}
            </button>
          </h4>
          <div :id="`publishedTable${ind}`" class="accordion-collapse collapse"
            :aria-labelledby="`publishedTitle${ind}`" :data-bs-parent="`#accordionTasks${ind}`">
            <div class="bg-white p-0 accordion-card rounded shadow">
              <div class="row mx-0 py-3">
                <div class="col-md-9 d-flex flex-row justify-content-evenly mx-0">
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.readings }}</h4>
                    <p class="detail-text">Lessons</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.files }}</h4>
                    <p class="detail-text">Files</p>
                  </div>
                  <div class="text-center">
                    <h4 class="detail-number">{{ mod.assessments }}</h4>
                    <p class="detail-text">Assignments</p>
                  </div>
                </div>
                <div class="col-md-3 text-end" v-if="editable">
                  <button class="btn btn-line-dark">
                    <i class="material-icons align-middle"
                    @click="loadEdit(mod)"> edit </i>
                  </button>
                  <button class="btn btn-line-danger">
                    <i class="material-icons align-middle"
                    @click="deleteModule(mod)"> delete </i>
                  </button>
                </div>
                <div class="col-md-3 text-end" v-else>
                  <button class="btn btn-line-dark">
                    <i class="material-icons align-middle"
                    @click="loadEdit(mod)"> open_in_new </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import {
  required,
} from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  name: 'ModulesList',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      courseId: null,
      modules: [],
      newModule: '',
      moduleModal: null,
      submitNewModule: false,
      selectedModule: null,
      editable: false,
      // mode: null,
      // code: null,
    };
  },
  validations: {
    newModule: {
      required,
    },
  },
  methods: {
    getModules() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module', {
        params: {
          courseId: this.courseId,
        },
      })
        .then((response) => {
          this.modules = response.data;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    loadModuleCreation() {
      this.moduleModal = new Modal(document.getElementById('createModule', {}));
      this.moduleModal.show();
    },
    createModule() {
      this.$store.commit('setLoadScreen', { status: true });
      const moduleData = {
        courseId: this.courseId,
        title: this.newModule,
        seq: this.modules.length,
        role: this.userData.roleId,
      };
      this.submitNewModule = true;
      axios.post('/api/module/create', moduleData)
        .then((response) => {
          this.modules.push({
            id: response.data.moduleId,
            title: this.newModule,
            seq: this.modules.length,
          });
          this.moduleModal.hide();
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.moduleModal.hide();
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
    loadEdit(mod) {
      // this.$router.push(`editmodule/${seq}`);
      this.$parent.selectedModule = mod;
    },
    deleteModule(mod) {
      Swal.fire({
        title: 'Are you sure you want to delete this module?',
        text: 'All associated content (except assignments) will be deleted in 24 hours',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.commit('setLoadScreen', { status: true });
            axios.post('/api/module/delete', {
              modId: mod.id,
              role: this.userData.roleId,
            })
              .then(() => {
                this.$store.commit('setLoadScreen', { status: false });
                this.getModules();
              })
              .catch((err) => {
                this.$store.commit('setLoadScreen', { status: false });
                console.log(err);
              });
          }
        });
    },
    getCourseInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.courseId}`, {
        params: {
          role: this.userData.roleId,
          user: this.userData.id,
        },
      })
        .then((response) => {
          this.$store.commit('setLoadScreen', { status: false });
          this.editable = !response.data.published;
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.getModules();
    this.getCourseInfo();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.accordion-item {
  background-color: transparent;
  border:  none;
}
.accordion-title button{
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(0,33,71,1) 60%,
    rgba(0,62,109,1) 75%, rgba(0,103,165,1) 90%);
}
/*.accordion-card{
  max-height: 23em !important;
  overflow-y: scroll;
}*/
.accordion-button:not(.collapsed) {
  border-radius: 0.5rem 0.5rem 0 0 !important;
}
.accordion-button {
  border-radius: 0.5rem !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.detail-number {
  color:  var(--accent-1);
}
.detail-text {
  color:  var(--dark-color);
  font-weight: bold;
  margin-bottom: 0;
}

</style>
