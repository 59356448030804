<template>
  <div>
    <button class="btn mb-2 text-primary" @click="$parent.selectedModule = null"> Go back </button>
    <div class="row mt-5">
      <div class="col-md-3">
        <div class="content-sidebar">
          <ul class="content-list">
            <li class="content-item" :class="{active: activeItem.id === item.id}"
            @click="setActive(item)"
            v-for="(item, index) in content" :key="index">
              <div v-if="item.type === 1">
                <div>
                  <p>
                    <b class="d-block"> Assessment:
                      <span v-show="item.completed">
                        <i class="material-icons text-muted align-middle"
                        style="font-size: 1rem;">check_circle</i>
                      </span>
                    </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="item.type === 2">
                <div>
                   <p>
                    <b class="d-block"> File:
                      <span v-show="item.completed">
                        <i class="material-icons text-muted align-middle"
                        style="font-size: 1rem;">check_circle</i>
                      </span>
                    </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="item.type === 3">
                <div>
                   <p>
                    <b class="d-block"> Reading:
                      <span v-show="item.completed">
                        <i class="material-icons text-muted align-middle"
                        style="font-size: 1rem;">check_circle</i>
                      </span>
                    </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="item.type === 4">
                <div>
                  <p>
                    <b class="d-block"> Lesson:
                      <span v-show="item.completed">
                        <i class="material-icons text-muted align-middle"
                           style="font-size: 1rem;">check_circle</i>
                      </span>
                    </b>
                    <span v-if="item.title">
                      {{item.title}}
                    </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9 content-viewer">
        <ModuleAssessmentView v-if="activeItem && activeItem.type === 1"
        :content-id="activeItem.contentId" :item="activeItem">
        </ModuleAssessmentView>
        <ModuleFileConfig v-else-if="activeItem && (activeItem.type === 2  || activeItem.type === 4)"
        :content-id="activeItem.contentId" :item="activeItem">
        </ModuleFileConfig>
        <ModuleReadingConfig v-else-if="activeItem && activeItem.type === 3"
        :content-id="activeItem.contentId" :item="activeItem">
        </ModuleReadingConfig>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import ModuleAssessmentView from './ModuleAssessmentView.vue';
import ModuleReadingConfig from './ModuleReadingConfig.vue';
import ModuleFileConfig from './ModuleFileConfig.vue';

export default {
  name: 'StudentModulesDetails',
  props: ['selected'],
  components: {
    ModuleAssessmentView,
    ModuleReadingConfig,
    ModuleFileConfig,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      courseId: null,
      content: [],
      assObj: [],
      activeItem: null,
      // mode: null,
      // code: null,
    };
  },
  methods: {
    getModuleDetails() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/module/student-details', {
        params: {
          courseId: this.courseId,
          seq: this.selected.seq,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.content = response.data;
          [this.activeItem] = this.content;
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.error(err);
        });
    },
    setActive(item) {
      this.activeItem = item;
    },
    getAssInfo(id) {
      return this.assObj.filter((ass) => ass.id === id)[0];
    },
    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/assessment/course/${this.courseId}`, {
        params: {
          user: this.userData.id,
          role: this.userData.roleId,
        },
      })
        .then((response) => {
          this.assObj = response.data.assignmentList;
          this.$store.commit('setLoadScreen', { status: false });
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    this.courseId = this.$route.params.cid;
    this.getAss();
    this.getModuleDetails();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
.content-box {
  background-color: rgba(251, 240, 218, 0.3);
  padding: 1rem 0;
}
.add-content {
  border:  dashed #ddd 2px;
  height: 5rem;
  margin: 1rem 2rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
}
.add-content i {
  font-size: 2rem;
  color:  var(--dark-color);
}
.content-list {
  list-style:  none;
  padding: 0;
  margin:  0;
  position: sticky;
}
.content-viewer {
  max-height: 90vh;
  overflow-y: auto;
}
.content-item {
  padding: 0.1rem 0.5rem;
  border-left: 4px transparent solid;
  cursor: pointer;
}
.content-item.active {
  border-left: 4px var(--accent-1) solid;
  background-color: rgba(0,0,0,0.05);
}
</style>
