<template>
  <div style="padding-top: 2rem" class="info-container p-0 mx-auto">
    <div v-if="userData.roleId == 2">
      <CourseModuleDetails v-if="selectedModule !== null" :selected="selectedModule">
      </CourseModuleDetails>
      <CourseModuleList v-else></CourseModuleList>
    </div>
    <div v-else-if="userData.roleId == 1">
      <StudentModuleDetails v-if="selectedModule !== null" :selected="selectedModule">
      </StudentModuleDetails>
      <StudentModuleList v-else></StudentModuleList>
    </div>
  </div>
</template>
<script>
import CourseModuleList from './CourseModulesList.vue';
import CourseModuleDetails from './CourseModuleDetails.vue';
import StudentModuleList from './StudentModulesList.vue';
import StudentModuleDetails from './StudentModuleDetails.vue';

export default {
  name: 'ModulesHome',
  components: {
    CourseModuleDetails,
    CourseModuleList,
    StudentModuleDetails,
    StudentModuleList,
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      selectedModule: null,
      // mode: null,
      // code: null,
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1400px;
}
</style>
